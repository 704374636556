.full-page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.full-page-loader__orb {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  animation: 1.8s infinite heartbeat;
  background-color: #1b1d3d;
  width: 200px;
  height: 200px;
}

.full-page-loader__orb > svg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
